import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import tw from "twin.macro";
import { styled } from "twin.macro";

import Bullet from "../../static/icons/play.svg";

interface Props {
  list: string[];
}

const ListItem = styled.li`
  ${tw`mb-4`}
  svg {
    ${tw`fill-current text-turquoise inline h-6 ml--2 mr-1 mt--1 transition`}
  }
`;
const ListWithBullet: React.FC<Props> = ({ list, ...rest }) => {
  const { t } = useTranslation();

  return (
    <ul tw="text-left" {...rest}>
      {list.map((item, i) => (
        <ListItem key={i}>
          <Bullet /> {t(item)}
        </ListItem>
      ))}
    </ul>
  );
};

export default ListWithBullet;
