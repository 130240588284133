import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import "twin.macro";

import { Container } from "../components/Container";
import AnimatedSvg from "./AnimatedSvg";
import ListWithBullet from "./ListWithBullet";
import Slogan from "./Slogan";
import TheBigTrioLinks from "./TheBigTrioLinks";

interface Params {
  header: string;
  subheader: string;
  icon: React.ReactNode;
  list: string[];
  extraContent?: any;
  linkTrioMarginT?: string;
}

const TheBigTrio: React.FC<Params> = ({ header, subheader, icon, list, extraContent, linkTrioMarginT, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Container tw="pt-16 pb-32 text-center" {...rest}>
      <h1 tw="font-black text-5xl">{t(header)}</h1>
      <p tw="mt-8 mb-16 mx-auto text-2xl max-w-2xl">{t(subheader)}</p>

      <article tw="md:(flex w-10/12 m-auto)">
        <AnimatedSvg>
          <div tw="flex-1 all-child:(fill-current text-turquoise mx-auto my-4 h-48 md:h-60 md:mr-16 md:ml-auto)">{icon}</div>
        </AnimatedSvg>
        <ListWithBullet list={list} tw="flex-auto" />
      </article>
      {extraContent}
      <TheBigTrioLinks marginTop={linkTrioMarginT} />

      <Slogan />
    </Container>
  );
};

export default TheBigTrio;
