import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import tw, { css } from "twin.macro";

import { materials } from "../../consts/materialList";
import { Container } from "../Container";
import { useModal } from "../Modal";
import MaterialSourcingPopup from "./MaterialSourcingPopup";

interface Props {
  label: string;
  isOutlined: boolean;
  isBig: boolean;
  commentFirst?: boolean;
  skipComment?: boolean;
}

const somethingBetter = css``;

export const MaterialSourcingCards: React.FC<Partial<Props>> = ({ label, isOutlined, isBig, commentFirst, skipComment, ...rest }) => {
  const { t } = useTranslation();
  const [showAllButtons, setShowAllButtons] = useState(false);

  const ButtonList = () => {
    const handleLoadMore = () => {
      setShowAllButtons(true);
    };

    return (
      <div
        tw="grid grid-cols-1 sm:grid-cols-2 sml:grid-cols-3 mt-0 md:(max-w-[62.5rem] grid-cols-4) lgl:grid-cols-5 mx-auto"
        css={somethingBetter}
      >
        {materials.slice(0, showAllButtons ? materials.length : 10).map((material, index) => {
          const materialModal = useModal();
          return (
            <React.Fragment key={material.id}>
              <button
                tw="my-3 text-turquoise hover:(text-turquoise-dark) hover:shadow-xl motion-safe:hover:scale-105 border-2 rounded-xl relative inline-flex ssm:(mx-auto min-w-[240px] min-h-[240px]) w-auto h-auto sm:(w-48 h-48 min-w-0 min-h-0 m-3)"
                onClick={() => materialModal.open()}
              >
                <div
                  tw="relative w-full h-full"
                  style={{ aspectRatio: "1/1" }} // Maintain 1:1 aspect ratio for the button
                >
                  <img tw="absolute inset-0 object-cover w-full h-full" src={material.image} alt="Material alt" />
                  <p tw="absolute bottom-0 bg-gray-500 bg-opacity-70 w-full py-2 px-4 text-white">{t(material.materialName)}</p>
                </div>
              </button>
              {index === 9 && !showAllButtons && (
                <button
                  tw="m-3 text-white items-center justify-center bg-gray-500 bg-opacity-70 hover:bg-opacity-80 border-2 rounded-xl relative inline-flex col-span-full"
                  onClick={handleLoadMore}
                >
                  Load More
                </button>
              )}
              <MaterialSourcingPopup useModal={materialModal} material={material} />
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <Container {...rest}>
      <div tw="text-center border-none" {...rest}>
        <ButtonList />
      </div>
    </Container>
  );
};

export default MaterialSourcingCards;
