import { MutableRefObject, useRef } from "react";
import tw from "twin.macro";

import { useOnScreen } from "../utils/common";

interface Props {
  fromRight?: boolean;
}

const AnimatedSvg: React.FC<Props> = ({ children, fromRight = false }) => {
  const ref = useRef() as MutableRefObject<HTMLSpanElement>;
  const isVisible = useOnScreen(ref);

  return (
    <span
      ref={ref}
      css={[
        tw`transform transition delay-300 duration-300 opacity-0 motion-reduce:(transition-none transform-none opacity-100)`,
        tw`translate-y-20`,
        isVisible && tw`translate-y-0 opacity-100`
        // fromRight ? tw`translate-x-80 -rotate-45` : tw`-translate-x-80 rotate-45`,
        // isVisible && tw`translate-x-0 opacity-100 rotate-0`
      ]}
    >
      {children}
    </span>
  );
};

export default AnimatedSvg;
