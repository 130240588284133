import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import "twin.macro";

import { Register } from "../components/Register";

const Slogan: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div tw="text-center max-w-screen-lg m-auto">
      <p tw="mt-32 mb-8 mx-auto text-2xl font-bold">{t("slogan")}</p>
      <Register label="nav.joinUs" />
    </div>
  );
};

export default Slogan;
