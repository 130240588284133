import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import MaterialSourcingSvg from "../../static/images/material-sourcing.svg";
import { Layout } from "../components/Layout/Layout";
import { MaterialSourcingCards } from "../components/material_sourcing/MaterialSourcingCards";
import TheBigTrio from "../components/TheBigTrio";
import { getTranslationList } from "../utils/common";

const MaterialSourcing: React.FC = () => {
  const { t } = useTranslation();
  const cards = css`
    ${tw`border-t pt-2 px-0 mt-5`}
  `;
  const list = getTranslationList(t, "materialSourcing.list");

  return (
    <Layout>
      <Helmet title={t("materialSourcing.header")} />
      <TheBigTrio
        header="materialSourcing.header"
        subheader="materialSourcing.subheader"
        icon={<MaterialSourcingSvg />}
        list={list}
        linkTrioMarginT="32px"
        extraContent={<MaterialSourcingCards css={cards} />}
      />
    </Layout>
  );
};

export default MaterialSourcing;
