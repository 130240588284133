import React from "react";
import { useTranslation } from "react-i18next";
import "twin.macro";

import { getTranslationList } from "../../utils/common";
import AnimatedSvg from "../AnimatedSvg";
import { BookMeeting } from "../BookMeeting";
import { Container } from "../Container";
import ListWithBullet from "../ListWithBullet";
import Modal from "../Modal";

interface Props {
  useModal: any;
  material?: any;
}

const MaterialSourcingPopup: React.FC<Props> = ({ useModal, material, ...rest }) => {
  const { t } = useTranslation();
  const list = getTranslationList(t, material.list);

  const handleClose = () => {
    useModal.close();
  };
  return (
    <Modal usedModal={useModal} maxWidth="90%" height="100%" overflowY="auto" maxHeight="98vh" {...rest}>
      <Container tw="pt-1 pb-1 text-center">
        <button
          tw="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={handleClose}
          aria-label="Close"
        >
          X
        </button>
        <h1 tw="font-black text-5xl">{t(material.materialName)}</h1>
        <p tw="mt-8 mb-2 mx-auto text-2xl max-w-2xl md:(mb-16)">{t(material.description)}</p>
        <article tw="md:flex">
          <AnimatedSvg>
            <div tw="flex-1 mx-auto mb-8 all-child:(mx-auto my-4 h-48 md:h-60 md:mr-16 md:ml-auto) max-w-[15rem] max-h-[13rem] sm:(w-60 h-52 max-w-full max-h-full) md:(mb-0) relative">
              <div tw="w-full md:mt-0" style={{ paddingTop: "100%" }}>
                <img tw="absolute top-0 left-0 w-full h-full object-cover" src={material.image} alt={t(material.materialName)} />
              </div>
            </div>
          </AnimatedSvg>
          <div tw="flex-auto ml-1 sm:ml-10">
            <ListWithBullet list={list} />
          </div>
        </article>
        <BookMeeting skipComment={true} />
      </Container>
    </Modal>
  );
};

export default MaterialSourcingPopup;
