import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import "twin.macro";
import tw, { styled } from "twin.macro";

import EquipmentOwnerSvg from "../../static/images/equipment-owners.svg";
import ExpertsSvg from "../../static/images/experts.svg";
import MaterialOwnerSvg from "../../static/images/material-owners.svg";
import MaterialSourcingSvg from "../../static/images/material-sourcing.svg";

interface Props {
  marginTop?: string;
}

const Tile = styled(Link)`
  &:hover {
    ${tw`text-turquoise`}

    svg {
      ${tw`text-turquoise-dark`}
    }
  }

  h4 {
    ${tw`font-bold text-2xl`}
  }

  svg {
    ${tw`fill-current text-turquoise inline my-8 h-32`}
  }
`;

const TheBigTrioLinks: React.FC<Props> = ({ marginTop }) => {
  const { t } = useTranslation();
  const modifiedMarginTop = marginTop ? { marginTop } : {};

  return (
    <section
      tw="flex flex-col justify-evenly my-16 py-16 border-b border-t border-grey-light text-center sm:flex-row "
      style={modifiedMarginTop}
    >
      <Tile to="/material-owners">
        <h4>{t("materialOwners.header")}</h4>
        <MaterialOwnerSvg />
      </Tile>
      <Tile to="/equipment-owners">
        <h4>{t("equipmentOwners.header")}</h4>
        <EquipmentOwnerSvg />
      </Tile>
      <Tile to="/experts">
        <h4>{t("experts.header")}</h4>
        <ExpertsSvg />
      </Tile>
      <Tile to="/material-sourcing">
        <h4>{t("materialSourcing.header")}</h4>
        <MaterialSourcingSvg />
      </Tile>
    </section>
  );
};

export default TheBigTrioLinks;
