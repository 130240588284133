import butterReplacer from "../../static/images/materialSourcing/ButterReplacement.webp";
import cocoaButter from "../../static/images/materialSourcing/CocoaButter.webp";
import cocoaMass from "../../static/images/materialSourcing/CocoaMass.webp";
import cocoaPowder from "../../static/images/materialSourcing/CocoaPowder.webp";
import coconutOil from "../../static/images/materialSourcing/CoconutOil.webp";
import dairyBasedInstantFatPowders from "../../static/images/materialSourcing/DairyBasedAndInstantFatPowders.webp";
import driedSourdoughWheat from "../../static/images/materialSourcing/DriedSourdough.webp";
import eggPowder from "../../static/images/materialSourcing/EggPowder.webp";
import fryingFat from "../../static/images/materialSourcing/FryingFat.webp";
import grapeSeedFlour from "../../static/images/materialSourcing/GrapeSeedFlour.webp";
import arabicGum from "../../static/images/materialSourcing/GumArabic.webp";
import hydrolyzedCasein from "../../static/images/materialSourcing/HydrolyzedCasein.webp";
import margarine from "../../static/images/materialSourcing/Margarine.webp";
import oliveStoneFlourM200 from "../../static/images/materialSourcing/OliveStonePowder.webp";
import palmOil from "../../static/images/materialSourcing/PalmOil.webp";
import riceFlourM200 from "../../static/images/materialSourcing/RiceFlour.webp";
import ryeBranMicronisedM200 from "../../static/images/materialSourcing/RyeBran.webp";
import ryeFlourR8004 from "../../static/images/materialSourcing/RyeFlour.webp";
import soybeanLecithin from "../../static/images/materialSourcing/SoybeanLecithin.webp";
import sunflowerLecithin from "../../static/images/materialSourcing/SunflowerLecithin.webp";
import sunflowerOil from "../../static/images/materialSourcing/SunflowerOil.webp";
import wheatBranMicronisedM200 from "../../static/images/materialSourcing/WheatBranMicronized.webp";
import wheatFlourWM550T34 from "../../static/images/materialSourcing/WheatFlour.webp";
import wholeBarleyFlourRoastedMR200 from "../../static/images/materialSourcing/WholeBarleyFlour.webp";

export const materials = [
  {
    id: 1,
    materialName: "materialSourcing.materials.cocoaButter.name",
    description: "materialSourcing.materials.cocoaButter.description",
    list: "materialSourcing.materials.cocoaButter.list",
    image: cocoaButter
  },
  {
    id: 2,
    materialName: "materialSourcing.materials.cocoaPowder.name",
    description: "materialSourcing.materials.cocoaPowder.description",
    list: "materialSourcing.materials.cocoaPowder.list",
    image: cocoaPowder
  },
  {
    id: 3,
    materialName: "materialSourcing.materials.cocoaMass.name",
    description: "materialSourcing.materials.cocoaMass.description",
    list: "materialSourcing.materials.cocoaMass.list",
    image: cocoaMass
  },
  {
    id: 4,
    materialName: "materialSourcing.materials.coconutOil.name",
    description: "materialSourcing.materials.coconutOil.description",
    list: "materialSourcing.materials.coconutOil.list",
    image: coconutOil
  },
  {
    id: 5,
    materialName: "materialSourcing.materials.palmOil.name",
    description: "materialSourcing.materials.palmOil.description",
    list: "materialSourcing.materials.palmOil.list",
    image: palmOil
  },
  {
    id: 6,
    materialName: "materialSourcing.materials.sunflowerLecithin.name",
    description: "materialSourcing.materials.sunflowerLecithin.description",
    list: "materialSourcing.materials.sunflowerLecithin.list",
    image: sunflowerLecithin
  },
  {
    id: 7,
    materialName: "materialSourcing.materials.soybeanLecithin.name",
    description: "materialSourcing.materials.soybeanLecithin.description",
    list: "materialSourcing.materials.soybeanLecithin.list",
    image: soybeanLecithin
  },
  {
    id: 8,
    materialName: "materialSourcing.materials.dairyBasedInstantFatPowders.name",
    description: "materialSourcing.materials.dairyBasedInstantFatPowders.description",
    list: "materialSourcing.materials.dairyBasedInstantFatPowders.list",
    image: dairyBasedInstantFatPowders
  },
  {
    id: 9,
    materialName: "materialSourcing.materials.sunflowerOil.name",
    description: "materialSourcing.materials.sunflowerOil.description",
    list: "materialSourcing.materials.sunflowerOil.list",
    image: sunflowerOil
  },
  {
    id: 10,
    materialName: "materialSourcing.materials.margarine.name",
    description: "materialSourcing.materials.margarine.description",
    list: "materialSourcing.materials.margarine.list",
    image: margarine
  },
  {
    id: 11,
    materialName: "materialSourcing.materials.fryingFat.name",
    description: "materialSourcing.materials.fryingFat.description",
    list: "materialSourcing.materials.fryingFat.list",
    image: fryingFat
  },
  {
    id: 12,
    materialName: "materialSourcing.materials.butterReplacer.name",
    description: "materialSourcing.materials.butterReplacer.description",
    list: "materialSourcing.materials.butterReplacer.list",
    image: butterReplacer
  },
  {
    id: 13,
    materialName: "materialSourcing.materials.eggPowder.name",
    description: "materialSourcing.materials.eggPowder.description",
    list: "materialSourcing.materials.eggPowder.list",
    image: eggPowder
  },
  {
    id: 14,
    materialName: "materialSourcing.materials.hydrolyzedCasein.name",
    description: "materialSourcing.materials.hydrolyzedCasein.description",
    list: "materialSourcing.materials.hydrolyzedCasein.list",
    image: hydrolyzedCasein
  },
  {
    id: 15,
    materialName: "materialSourcing.materials.arabicGum.name",
    description: "materialSourcing.materials.arabicGum.description",
    list: "materialSourcing.materials.arabicGum.list",
    image: arabicGum
  },
  {
    id: 16,
    materialName: "materialSourcing.materials.wheatBranMicronisedM200.name",
    description: "materialSourcing.materials.wheatBranMicronisedM200.description",
    list: "materialSourcing.materials.wheatBranMicronisedM200.list",
    image: wheatBranMicronisedM200
  },
  {
    id: 17,
    materialName: "materialSourcing.materials.ryeBranMicronisedM200.name",
    description: "materialSourcing.materials.ryeBranMicronisedM200.description",
    list: "materialSourcing.materials.ryeBranMicronisedM200.list",
    image: ryeBranMicronisedM200
  },
  {
    id: 18,
    materialName: "materialSourcing.materials.driedSourdough.name",
    description: "materialSourcing.materials.driedSourdough.description",
    list: "materialSourcing.materials.driedSourdough.list",
    image: driedSourdoughWheat
  },
  {
    id: 19,
    materialName: "materialSourcing.materials.wheatFlourWM550T34.name",
    description: "materialSourcing.materials.wheatFlourWM550T34.description",
    list: "materialSourcing.materials.wheatFlourWM550T34.list",
    image: wheatFlourWM550T34
  },
  {
    id: 20,
    materialName: "materialSourcing.materials.ryeFlourR8004.name",
    description: "materialSourcing.materials.ryeFlourR8004.description",
    list: "materialSourcing.materials.ryeFlourR8004.list",
    image: ryeFlourR8004
  },
  {
    id: 21,
    materialName: "materialSourcing.materials.oliveStoneFlourM200.name",
    description: "materialSourcing.materials.oliveStoneFlourM200.description",
    list: "materialSourcing.materials.oliveStoneFlourM200.list",
    image: oliveStoneFlourM200
  },
  {
    id: 22,
    materialName: "materialSourcing.materials.grapeSeedFlour.name",
    description: "materialSourcing.materials.grapeSeedFlour.description",
    list: "materialSourcing.materials.grapeSeedFlour.list",
    image: grapeSeedFlour
  },
  {
    id: 23,
    materialName: "materialSourcing.materials.wholeBarleyFlourRoastedMR200.name",
    description: "materialSourcing.materials.wholeBarleyFlourRoastedMR200.description",
    list: "materialSourcing.materials.wholeBarleyFlourRoastedMR200.list",
    image: wholeBarleyFlourRoastedMR200
  },
  {
    id: 24,
    materialName: "materialSourcing.materials.riceFlourM200.name",
    description: "materialSourcing.materials.riceFlourM200.description",
    list: "materialSourcing.materials.riceFlourM200.list",
    image: riceFlourM200
  }
];
